<template>
  <div id="defaultId1">
    <div class="pb20">
      <gui_card v-if="profitablitylist?.length > 0"
        id="ge6845"
        class="uc1096"
        label=""
        :isShowLabel="false"
      >
        <div>
          <h2 class="operations-head flex_center_between_box">
            <span class="head-titel detail_title_20"
              >Profitability Ratios
              <svg
                @click="createDialog = true"
                t="1718848057496"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                style="margin-left: 20px; cursor: pointer"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4445"
                width="2.28rem"
                height="2.28rem"
              >
                <path
                  d="M935.812877 88.243078h-87.685776V29.784188a29.226886 29.226886 0 1 0-58.453771 0v58.45889H234.331789V29.784188a29.226886 29.226886 0 1 0-58.453771 0v58.45889H88.187123C39.762336 88.243078 0.501347 127.498948 0.501347 175.923735v759.945098c0 48.429907 39.260989 87.685776 87.685776 87.685776h847.625754c48.424787 0 87.685776-39.260989 87.685776-87.685776V175.928854c0-48.424787-39.260989-87.685776-87.685776-87.685776z m29.226886 847.625755a29.232005 29.232005 0 0 1-29.226886 29.232005H88.187123a29.232005 29.232005 0 0 1-29.226886-29.232005V351.300406h906.079526v584.568427z m0-643.027317H58.960237V175.928854a29.226886 29.226886 0 0 1 29.226886-29.226885h87.685776v29.226885a29.226886 29.226886 0 1 0 58.453771 0v-29.226885h555.341541v29.226885a29.226886 29.226886 0 1 0 58.453771 0v-29.226885h87.685776a29.226886 29.226886 0 0 1 29.226885 29.226885V292.841516z"
                  p-id="4446"
                  fill="#1290C9"
                ></path>
                <path
                  d="M512 526.671958c24.179117 0 43.842888-19.663771 43.842888-43.842888A43.842888 43.842888 0 0 0 512 438.986182a43.807052 43.807052 0 0 0-43.842888 43.842888c0 24.173998 19.597218 43.842888 43.842888 43.842888zM745.825323 877.409942c24.184237 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888c0.005119 24.179117 19.602337 43.842888 43.842888 43.842888zM512 702.03839c24.179117 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768A43.842888 43.842888 0 0 0 512 702.03839zM745.825323 526.671958c24.184237 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.807052 43.807052 0 0 0-43.842888 43.842888c0.005119 24.173998 19.602337 43.842888 43.842888 43.842888zM745.825323 702.03839c24.184237 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768c0.005119 24.184237 19.602337 43.842888 43.842888 43.842888zM512 877.409942c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 877.409942c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 702.03839c24.179117 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 526.671958c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.807052 43.807052 0 0 0-43.842888 43.842888c0 24.173998 19.597218 43.842888 43.842888 43.842888z"
                  p-id="4447"
                  fill="#1290C9"
                ></path>
              </svg>
            </span>
            <span class="ViewMore contclass " @click="scrollToTop('Profitability Ratios')">Financial data from the related companies</span>
          </h2>
          <tableVue
            :profitablitylist="profitablitylist"
            :yearslist="yearslist1"
            :untia="untia"
            :createDialog="createDialog"
            @createDialogcheck="createDialogcheck"
          ></tableVue>
        </div>
      </gui_card>
      <!-- Operating Ratios -->
      <gui_card v-if="profitablitylist2?.length > 0"
        id="ge6845"
        class="uc1096 mt10"
        label=""
        :isShowLabel="false"
      >
        <div>
          <h2 class="operations-head flex_center_between_box">
            <span class="head-titel"
              >Operating Ratios
              <svg
                @click="createDialog2 = true"
                t="1718848057496"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                style="margin-left: 20px; cursor: pointer"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4445"
                width="2.28rem"
                height="2.28rem"
              >
                <path
                  d="M935.812877 88.243078h-87.685776V29.784188a29.226886 29.226886 0 1 0-58.453771 0v58.45889H234.331789V29.784188a29.226886 29.226886 0 1 0-58.453771 0v58.45889H88.187123C39.762336 88.243078 0.501347 127.498948 0.501347 175.923735v759.945098c0 48.429907 39.260989 87.685776 87.685776 87.685776h847.625754c48.424787 0 87.685776-39.260989 87.685776-87.685776V175.928854c0-48.424787-39.260989-87.685776-87.685776-87.685776z m29.226886 847.625755a29.232005 29.232005 0 0 1-29.226886 29.232005H88.187123a29.232005 29.232005 0 0 1-29.226886-29.232005V351.300406h906.079526v584.568427z m0-643.027317H58.960237V175.928854a29.226886 29.226886 0 0 1 29.226886-29.226885h87.685776v29.226885a29.226886 29.226886 0 1 0 58.453771 0v-29.226885h555.341541v29.226885a29.226886 29.226886 0 1 0 58.453771 0v-29.226885h87.685776a29.226886 29.226886 0 0 1 29.226885 29.226885V292.841516z"
                  p-id="4446"
                  fill="#1290C9"
                ></path>
                <path
                  d="M512 526.671958c24.179117 0 43.842888-19.663771 43.842888-43.842888A43.842888 43.842888 0 0 0 512 438.986182a43.807052 43.807052 0 0 0-43.842888 43.842888c0 24.173998 19.597218 43.842888 43.842888 43.842888zM745.825323 877.409942c24.184237 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888c0.005119 24.179117 19.602337 43.842888 43.842888 43.842888zM512 702.03839c24.179117 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768A43.842888 43.842888 0 0 0 512 702.03839zM745.825323 526.671958c24.184237 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.807052 43.807052 0 0 0-43.842888 43.842888c0.005119 24.173998 19.602337 43.842888 43.842888 43.842888zM745.825323 702.03839c24.184237 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768c0.005119 24.184237 19.602337 43.842888 43.842888 43.842888zM512 877.409942c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 877.409942c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 702.03839c24.179117 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 526.671958c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.807052 43.807052 0 0 0-43.842888 43.842888c0 24.173998 19.597218 43.842888 43.842888 43.842888z"
                  p-id="4447"
                  fill="#1290C9"
                ></path>
              </svg>
            </span>
            <span class="ViewMore contclass " @click="scrollToTop('Operating Ratios')">Financial data from the related companies</span>
          </h2>
          <tableVue
            :profitablitylist="profitablitylist2"
            :yearslist="yearslist1"
            :untia="untia"
            :createDialog="createDialog2"
            @createDialogcheck="createDialogcheck2"
          ></tableVue>
        </div>
      </gui_card>
      <!-- Structure Ratios -->
      <gui_card v-if="profitablitylist3?.length > 0"
        id="ge6845"
        class="uc1096 mt10"
        label=""
        :isShowLabel="false"
      >
        <div>
          <h2 class="operations-head flex_center_between_box">
            <span class="head-titel"
              >Structure Ratios
              <svg
                @click="createDialog3 = true"
                t="1718848057496"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                style="margin-left: 20px; cursor: pointer"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4445"
                width="2.28rem"
                height="2.28rem"
              >
                <path
                  d="M935.812877 88.243078h-87.685776V29.784188a29.226886 29.226886 0 1 0-58.453771 0v58.45889H234.331789V29.784188a29.226886 29.226886 0 1 0-58.453771 0v58.45889H88.187123C39.762336 88.243078 0.501347 127.498948 0.501347 175.923735v759.945098c0 48.429907 39.260989 87.685776 87.685776 87.685776h847.625754c48.424787 0 87.685776-39.260989 87.685776-87.685776V175.928854c0-48.424787-39.260989-87.685776-87.685776-87.685776z m29.226886 847.625755a29.232005 29.232005 0 0 1-29.226886 29.232005H88.187123a29.232005 29.232005 0 0 1-29.226886-29.232005V351.300406h906.079526v584.568427z m0-643.027317H58.960237V175.928854a29.226886 29.226886 0 0 1 29.226886-29.226885h87.685776v29.226885a29.226886 29.226886 0 1 0 58.453771 0v-29.226885h555.341541v29.226885a29.226886 29.226886 0 1 0 58.453771 0v-29.226885h87.685776a29.226886 29.226886 0 0 1 29.226885 29.226885V292.841516z"
                  p-id="4446"
                  fill="#1290C9"
                ></path>
                <path
                  d="M512 526.671958c24.179117 0 43.842888-19.663771 43.842888-43.842888A43.842888 43.842888 0 0 0 512 438.986182a43.807052 43.807052 0 0 0-43.842888 43.842888c0 24.173998 19.597218 43.842888 43.842888 43.842888zM745.825323 877.409942c24.184237 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888c0.005119 24.179117 19.602337 43.842888 43.842888 43.842888zM512 702.03839c24.179117 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768A43.842888 43.842888 0 0 0 512 702.03839zM745.825323 526.671958c24.184237 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.807052 43.807052 0 0 0-43.842888 43.842888c0.005119 24.173998 19.602337 43.842888 43.842888 43.842888zM745.825323 702.03839c24.184237 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768c0.005119 24.184237 19.602337 43.842888 43.842888 43.842888zM512 877.409942c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 877.409942c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 702.03839c24.179117 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 526.671958c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.807052 43.807052 0 0 0-43.842888 43.842888c0 24.173998 19.597218 43.842888 43.842888 43.842888z"
                  p-id="4447"
                  fill="#1290C9"
                ></path>
              </svg>
            </span>
            <span class="ViewMore contclass" @click="scrollToTop('Structure Ratios')">Financial data from the related companies</span>
          </h2>
          <tableVue
            :profitablitylist="profitablitylist3"
            :yearslist="yearslist1"
            :untia="untia"
            :createDialog="createDialog3"
            @createDialogcheck="createDialogcheck3"
          ></tableVue>
        </div>
      </gui_card>
      <!-- Productivity Ratios -->
      <gui_card v-if="profitablitylist4?.length > 0"
        id="ge6845"
        class="uc1096 mt10"
        label=""
        :isShowLabel="false"
      >
        <div>
          <h2 class="operations-head flex_center_between_box">
            <span class="head-titel"
              >Productivity Ratios
              <svg
                @click="createDialog4 = true"
                t="1718848057496"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                style="margin-left: 20px; cursor: pointer"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4445"
                width="2.28rem"
                height="2.28rem"
              >
                <path
                  d="M935.812877 88.243078h-87.685776V29.784188a29.226886 29.226886 0 1 0-58.453771 0v58.45889H234.331789V29.784188a29.226886 29.226886 0 1 0-58.453771 0v58.45889H88.187123C39.762336 88.243078 0.501347 127.498948 0.501347 175.923735v759.945098c0 48.429907 39.260989 87.685776 87.685776 87.685776h847.625754c48.424787 0 87.685776-39.260989 87.685776-87.685776V175.928854c0-48.424787-39.260989-87.685776-87.685776-87.685776z m29.226886 847.625755a29.232005 29.232005 0 0 1-29.226886 29.232005H88.187123a29.232005 29.232005 0 0 1-29.226886-29.232005V351.300406h906.079526v584.568427z m0-643.027317H58.960237V175.928854a29.226886 29.226886 0 0 1 29.226886-29.226885h87.685776v29.226885a29.226886 29.226886 0 1 0 58.453771 0v-29.226885h555.341541v29.226885a29.226886 29.226886 0 1 0 58.453771 0v-29.226885h87.685776a29.226886 29.226886 0 0 1 29.226885 29.226885V292.841516z"
                  p-id="4446"
                  fill="#1290C9"
                ></path>
                <path
                  d="M512 526.671958c24.179117 0 43.842888-19.663771 43.842888-43.842888A43.842888 43.842888 0 0 0 512 438.986182a43.807052 43.807052 0 0 0-43.842888 43.842888c0 24.173998 19.597218 43.842888 43.842888 43.842888zM745.825323 877.409942c24.184237 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888c0.005119 24.179117 19.602337 43.842888 43.842888 43.842888zM512 702.03839c24.179117 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768A43.842888 43.842888 0 0 0 512 702.03839zM745.825323 526.671958c24.184237 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.807052 43.807052 0 0 0-43.842888 43.842888c0.005119 24.173998 19.602337 43.842888 43.842888 43.842888zM745.825323 702.03839c24.184237 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768c0.005119 24.184237 19.602337 43.842888 43.842888 43.842888zM512 877.409942c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 877.409942c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 702.03839c24.179117 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 526.671958c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.807052 43.807052 0 0 0-43.842888 43.842888c0 24.173998 19.597218 43.842888 43.842888 43.842888z"
                  p-id="4447"
                  fill="#1290C9"
                ></path>
              </svg>
            </span>
              <span class="ViewMore contclass" @click="scrollToTop('Productivity Ratios')">Financial data from the related companies
            </span>
          </h2>
          <tableVue
            :profitablitylist="profitablitylist4"
            :yearslist="yearslist1"
            :untia="untia"
            :createDialog="createDialog4"
            @createDialogcheck="createDialogcheck4"
          ></tableVue>
        </div>
      </gui_card>
      <!-- Growth Ratios -->
      <gui_card v-if="profitablitylist5?.length > 0"
        id="ge6845"
        class="uc1096 mt10"
        label=""
        :isShowLabel="false"
      >
        <div>
          <h2 class="operations-head flex_center_between_box">
            <span class="head-titel"
              >Growth Ratios
              <svg
                @click="createDialog5 = true"
                t="1718848057496"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                style="margin-left: 20px; cursor: pointer"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4445"
                width="2.28rem"
                height="2.28rem"
              >
                <path
                  d="M935.812877 88.243078h-87.685776V29.784188a29.226886 29.226886 0 1 0-58.453771 0v58.45889H234.331789V29.784188a29.226886 29.226886 0 1 0-58.453771 0v58.45889H88.187123C39.762336 88.243078 0.501347 127.498948 0.501347 175.923735v759.945098c0 48.429907 39.260989 87.685776 87.685776 87.685776h847.625754c48.424787 0 87.685776-39.260989 87.685776-87.685776V175.928854c0-48.424787-39.260989-87.685776-87.685776-87.685776z m29.226886 847.625755a29.232005 29.232005 0 0 1-29.226886 29.232005H88.187123a29.232005 29.232005 0 0 1-29.226886-29.232005V351.300406h906.079526v584.568427z m0-643.027317H58.960237V175.928854a29.226886 29.226886 0 0 1 29.226886-29.226885h87.685776v29.226885a29.226886 29.226886 0 1 0 58.453771 0v-29.226885h555.341541v29.226885a29.226886 29.226886 0 1 0 58.453771 0v-29.226885h87.685776a29.226886 29.226886 0 0 1 29.226885 29.226885V292.841516z"
                  p-id="4446"
                  fill="#1290C9"
                ></path>
                <path
                  d="M512 526.671958c24.179117 0 43.842888-19.663771 43.842888-43.842888A43.842888 43.842888 0 0 0 512 438.986182a43.807052 43.807052 0 0 0-43.842888 43.842888c0 24.173998 19.597218 43.842888 43.842888 43.842888zM745.825323 877.409942c24.184237 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888c0.005119 24.179117 19.602337 43.842888 43.842888 43.842888zM512 702.03839c24.179117 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768A43.842888 43.842888 0 0 0 512 702.03839zM745.825323 526.671958c24.184237 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.807052 43.807052 0 0 0-43.842888 43.842888c0.005119 24.173998 19.602337 43.842888 43.842888 43.842888zM745.825323 702.03839c24.184237 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768c0.005119 24.184237 19.602337 43.842888 43.842888 43.842888zM512 877.409942c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 877.409942c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 702.03839c24.179117 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 526.671958c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.807052 43.807052 0 0 0-43.842888 43.842888c0 24.173998 19.597218 43.842888 43.842888 43.842888z"
                  p-id="4447"
                  fill="#1290C9"
                ></path>
              </svg>
            </span>
              <span class="ViewMore contclass" @click="scrollToTop('Growth Ratios')"
              >Financials From Related Companies
            </span>
          </h2>
          <tableVue
            :profitablitylist="profitablitylist5"
            :yearslist="yearslist1"
            :untia="untia"
            :createDialog="createDialog5"
            @createDialogcheck="createDialogcheck5"
          ></tableVue>
        </div>
      </gui_card>
      <gui_card
        v-if="Related"
        id="ge6845"
        class="uc1096 mt10"
        label=""
        :isShowLabel="false"
      >
        <div>
          <h2 class="operations-head flex_center_between_box">
            <span class="head-titel">Ratio Analysis from the Related Companies</span>
            <svg
              @click="Related = false"
              t="1718863593010"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="4791"
              width="2.28rem"
              height="2.28rem"
            >
              <path
                d="M547.4 512l278.2-278.2c9.8-9.8 9.8-25.6 0-35.4-9.8-9.8-25.6-9.8-35.4 0L512 476.6 233.8 198.4c-9.8-9.8-25.6-9.8-35.4 0-9.8 9.8-9.8 25.6 0 35.4L476.6 512 198.4 790.2c-9.8 9.8-9.8 25.6 0 35.4 4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3L512 547.4l278.2 278.2c4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3c9.8-9.8 9.8-25.6 0-35.4L547.4 512z"
                fill="#333333"
                p-id="4792"
              ></path>
            </svg>
          </h2>
          <!-- 关联公司 -->
          <div class="computer_right_cont_box" v-if="parentTablelist.length > 0">
            <div
              class="infinite-list-wrapper"
              v-infinite-scroll="infiniteScroll"
              style="overflow: auto"
              :infinite-scroll-disabled="false"
              :infinite-scroll-distance="5"
            >
              <div
                class="footer"
                v-for="(item, index) in parentTablelist"
                :key="index"
                v-loading="item.loading2"
              >
                <div class="ecahrts-header">
                  <span class="title-left">
                    <span
                      style="cursor: pointer; margin-right: 5px; width: 360px"
                      class="line_1 relate-left"
                      @click="setid(item)"
                      >{{ item.nameEn }}</span
                    >
                  </span>
                  <span class="relate-Financial" style="margin-left: 22%">
                    Ratio Analysis </span
                  >
                  <span
                    style="
                      display: flex;
                      justify-content: flex-start;
                      width: 280px;
                    "
                    class="line_1 relate-Financial"
                  >
                    Relations:{{ item.typeEn }}</span
                  >

                  <div class="head_loging">
                    <el-switch
                      active-color="#13ce66"
                      :value="loginStatus1[index]"
                      @change="loginPop(index, item.aaaid, item.nameEn)"
                    ></el-switch>
                  </div>
                </div>
                <div></div>
                <div v-if="loginStatus1[index]" class="ecahrtstu">
              <div v-show="modulesvalue=='Profitability Ratios'" style="width: 100%;">
                    <tabletwoVue
                    :profitablitylist="item.relatedanylays"
                    :yearslist="item.yearslist"
                    :dia="id3a"
                    :untia="untia"
                  ></tabletwoVue>
              </div>
                        <div v-show="modulesvalue=='Operating Ratios'" style="width: 100%;">
                    <tabletwoVue
                    :profitablitylist="item.relatedanylays1"
                    :yearslist="item.yearslist"
                    :dia="id3a"
                    :untia="untia"
                  ></tabletwoVue>
              </div>
                        <div v-show="modulesvalue=='Structure Ratios'" style="width: 100%;">
                    <tabletwoVue
                    :profitablitylist="item.relatedanylays2"
                    :yearslist="item.yearslist"
                    :dia="id3a"
                    :untia="untia"
                  ></tabletwoVue>
              </div>
                        <div v-show="modulesvalue=='Productivity Ratios'" style="width: 100%;">
                    <tabletwoVue
                    :profitablitylist="item.relatedanylays3"
                    :yearslist="item.yearslist"
                    :dia="id3a"
                    :untia="untia"
                  ></tabletwoVue>
              </div>
                        <div v-show="modulesvalue=='Growth Ratios'" style="width: 100%;">
                    <tabletwoVue
                    :profitablitylist="item.relatedanylays4"
                    :yearslist="item.yearslist"
                    :dia="id3a"
                    :untia="untia"
                  ></tabletwoVue>
              </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <tableVue
            :profitablitylist="profitablitylist"
            :yearslist="yearslist1"
            :untia="untia"
            :createDialog="createDialog"
            @createDialogcheck="createDialogcheck"
          ></tableVue> -->
        </div>
      </gui_card>
    </div>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>
  <!--srcviewscompanydetailsBackgroundSnapshotvuehtmlEnd-->
</template>
<script>
// interfaceCode
//importStart
import {
getrelatedCompanylist,
  getFinancials,
  getPerformanceSummary
} from "@/api/companyApi";
import rectcard from "../../../../components/rectCard/index.vue";
import content_text from "../../../../components/text_content/index.vue";
import gui_card from "../../../../components/gui_card/gui_card.vue";
import tableVue from "../Performance/components/table.vue";
import tabletwoVue from './components/tabletwo.vue';
import ReLogin from "../../../../components/ReLogin.vue";

//importEnd

export default {
  name: "",

  props: {},

  components: {
    // gui_card start
    gui_card,
    // gui_card end
tabletwoVue,
    rectcard,
    content_text,
    tableVue,
    ReLogin,
  },
  created(){
    this.getlist();
  },
   computed: {},

  mounted() {

          this.getPerformanceSummaryList()
  },
  data() {
    return {
      reLoginVis: false,
      Related: false,
      untia: "",
      yearslist1: [],
           page: {
                pageNo: 1,
                pageSize: 20,
                total: 0,
            },
      yearslist2: [],
      modulesvalue:'',
      createDialog: false,
      createDialog2: false,
      createDialog3: false,
      createDialog4: false,
      createDialog5: false,
      parentTablelist: [],
      loginStatus1: [],
      id3a: [],
      profitablitylist: [],
      profitablitylist2: [],
      profitablitylist3: [],
      profitablitylist4: [],
      profitablitylist5: [],
      companyList: [],
      noMore: false, // 控制滚动禁用
      routeLoad: false, // 控制滚动禁用
      icon: "",
      showBackToTop: false,
      newDialog: false,
      // srcviewscompanydetailsBackgroundSnapshotvueDataEnd
    };
  },
  methods: {
    getPerformanceSummaryList(){
      getPerformanceSummary({id3a:this.$route.query.id3a}).then(res=>{
        if(res.code==200){
        let manManagementSummary= [
          {
            name: "Revenue",
            id: "Revenue",
            num:res.data.revenue,
          },
          {
            name: "Profit",
            id: "Profit",
            num:res.data.profit,
          },
          {
            name: "Growth",
            id: "Growth",
              num:res.data.growth,
          },
          {
            name: "Cash Flow Status",
            id: "Cash Flow Status",
               num:res.data.liquidity,
          },
          {
            name: "Financial Structure",
            id: "Financial Structure",
               num:res.data.financialStructurt,
          },
          {
            name: "Credit Opinion",
            id: "Credit Opinion",
                num:res.data.creditOpinion,
          },
          {
            name: "Risk Indicator (RI)",
            id: "Risk Indicator (RI)",
                num:res.data.riskIndicatorLower,
          },
          {
            name: "Solvency index(SI)",
            id: "Solvency index(SI)",
                num:res.data.solvencyindexSI,
          },
        ]
        
    this.$emit('UpdatamarkTotalList',manManagementSummary)
       }else{
           this.$emit('UpdatamarkTotalList',[])
       }
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      })
    },
    scrollToTop(modulesvalue) {
      this.modulesvalue=modulesvalue
      this.Related = true;
      if(this.parentTablelist.length==0){
        this.getrelateList();

      }
    },
    createDialogcheck(value) {
      this.createDialog = value;
    },
    createDialogcheck2(value) {
      this.createDialog2 = value;
    },
    createDialogcheck3(value) {
      this.createDialog3 = value;
    },
    createDialogcheck4(value) {
      this.createDialog4 = value;
    },
    createDialogcheck5(value) {
      this.createDialog5 = value;
    },

    getlist() {
       const params = {
                id3a: this.$route.query.id3a,
  
            };
            getFinancials(params).then(res => {
                this.loading = false;
                let data=res
                // let data = JSON.parse(crypto.decrypt(res));

                let { ratios, years, unit } = data.data;
                this.profitablitylist = ratios[0].datas;
                this.profitablitylist2 = ratios[1].datas;
                this.profitablitylist3 = ratios[2].datas;
                this.profitablitylist4 = ratios[3].datas;
                this.profitablitylist5 = ratios[4].datas;
                this.yearslist1 = years;
                this.untia = unit;
            });
    },
    infiniteScroll() {
      this.routeLoad = false;
    },
    getrelateList() {
  

      getrelatedCompanylist({ id3a: this.$route.query.id3a,type:1, page: this.page.pageNo, pageSize: this.page.pageSize, resourceType: 1 }).then(res => {
                   if (res ) {
                    let list = res.data.rows;
                    this.parentTablelist = res.data.rows;
                    this.routeLoad = false;
                    this.parentTablelist.map(item => {
                        item.relatedanylays = [];
                        item.relatedanylays1 = [];
                        item.relatedanylays2 = [];
                        item.relatedanylays3 = [];
                        item.relatedanylays4 = [];
                        item.loading2 = false;
                        item.yearslist = [];
                    });
                }
      });
    },
    async loginPop(index, id, nameEn) {
      this.indexclose = index;
      this.$set(this.loginStatus1, index, !this.loginStatus1[index]);
      if (this.loginStatus1[index]) {
        //   this.loading1=true
        this.parentTablelist[index].loading2 = true;
        const params = {
          id3a: id,
  
        };
        if (true) {
        getFinancials(params).then(res => {
                        if (!res.code) {
                            this.parentTablelist[index].relatedprofitablitylist = [];
                            this.parentTablelist[index].relatedprofitablitylist1 = [];
                            this.parentTablelist[index].loading2 = false;
                        }
                        this.parentTablelist[index].loading2 = false;
                            let data = res             
                        // JSON.parse(crypto.decrypt());

                        let { ratios, years, unit } = data.data;
                        this.parentTablelist[index].yearslist = years;
                        this.id3a = years;

                        this.untia = unit;
                        this.parentTablelist[index].relatedanylays = ratios[0].datas;
                        this.parentTablelist[index].relatedanylays1 = ratios[1].datas;
                        this.parentTablelist[index].relatedanylays2 = ratios[2].datas;
                        this.parentTablelist[index].relatedanylays3 = ratios[3].datas;
                        this.parentTablelist[index].relatedanylays4 = ratios[4].datas;

                    });
        } 
      }
    },
    // insertMethod
    // srcviewscompanydetailsBackgroundSnapshotvueMethodStart
    // default
    default() {},
    // srcviewscompanydetailsBackgroundSnapshotvueMethodEnd
  },
 
};
</script>
<style scoped>
.uPdtqFl {
  padding-right: 2.857142857142857rem;
  padding-top: 2.142857142857143rem;
  padding-left: 2.857142857142857rem;
  padding-bottom: 2.142857142857143rem;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-top-left-radius: 0.2857142857142857rem;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-top-right-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
}
.importDetail-head {
  display: flex;
}
.flex_center_between_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
  cursor: pointer;
}
.ViewMore {
  line-height: 1.8571428571428572rem;
  text-align: left;
  font-style: normal;
  text-decoration-line: underline;
  margin-left: 2.142857142857143rem;
}
.uc1096 {
  height: 100%;
}
.head-titel {
  font-family: 'DMSans Bold';
  font-weight: 400;
  font-size: 2.142857142857143rem;
  display: flex;
  align-items: center;
}
.operations-head {
  height: 2.857142857142857rem;
  line-height: 2.857142857142857rem;
  font-family: 'DMSans Bold';
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title-left {
  display: flex;
  font-size: 1.1428571428571428rem;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #022955;
  padding-left: 1.4285714285714286rem;
  background-color: #f2f5f9;
}
.footer {
  padding-left: 0rem;
  overflow: hidden;
}

.ecahrts-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.07142857142857142rem solid #cccccc;
}
.title-left {
  display: flex;
  align-items: center;
  padding-bottom: 0.9285714285714286rem;
  padding-top: 0.9285714285714286rem;
}
.td {
  margin: 1.0714285714285714rem 0 0 1.7857142857142858rem;
}
.relate-left {
  height: 4.142857142857143rem;
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  line-height: 4.142857142857143rem;
  text-align: left;
  font-family: 'DMSans Bold';
}
.relate-Financial {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  font-size: Arial;
}
.computer_right_cont_box {
  margin-top: 1.4285714285714286rem;
}

.uUIRzUi {
  padding: 2.142857142857143rem 2.857142857142857rem;
}
.ecahrtstu{

  width: 100%;
}
</style>